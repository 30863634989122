import { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import cx from 'classnames'
import { formatDate } from 'constants/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faCircle,
  faFilter,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import { PROJECT_DETAILS, ROUTE_ENTITY_ID } from 'constants/routes'
import styles from './projects-table.module.scss'

import SearchBar from 'components/search'
import SnoozeForm from './snooze-form'
import ProjectsTable from './projects-table-view'

const progressFilterOptions = [
  { label: 'Grey', value: 'today' },
  { label: 'Green', value: 'ongoing' },
  { label: 'Amber', value: 'oneday' },
  { label: 'Red', value: 'expired' },
  { label: 'Completed', value: 'completed' },
  { label: 'Uncompleted', value: 'uncompleted' },
  { label: 'On hold', value: 'onHold' },
  { label: 'Archived', value: 'archived' },
  { label: 'Live', value: 'live' },
  { label: 'Exclude older than 14 days', value: 'excludeOlderThan14Days' },
]

function getSnoozeColor(snoozeType) {
  if (snoozeType === 'today') {
    return '#bdbdbd'
  }

  if (snoozeType === 'ongoing') {
    return '#4caf50'
  }

  if (snoozeType === 'oneday') {
    return '#ffbf00'
  }

  if (snoozeType === 'expired') {
    return '#f44336'
  }

  return '#000000'
}

function ProjectsTableContainer({
  projects,
  seeFavoriteProjects,
  showingFavoriteProjects,
  onSearchBy,
  searchBy,
  snoozeProject,
  onSelectProgress,
  team,
  onSelectAssegnee,
}) {
  const [projectToSnooze, setProjectToSnooze] = useState('')

  const history = useHistory()
  const params = useParams()
  const { id: selectedProjectId } = params

  const selectedProject = projects?.find(p => p.id === selectedProjectId)

  useEffect(() => {
    if (!selectedProject) {
      window.document.body.style.overflow = 'scroll'
    }
  }, [selectedProject])

  const assegneeFilterOptions = useMemo(
    () =>
      team?.map(user => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      })),
    [team],
  )

  function createClientsNames(projectClients) {
    return projectClients
      .map(
        client =>
          `${(client && client.firstName) || ''} ${
            (client && client.lastName) || ''
          }`,
      )
      .join(', ')
  }

  function onSnoozeProject(project, selectedPeriod) {
    return snoozeProject(project.id, selectedPeriod).finally(() => {
      setProjectToSnooze('')
      getSnoozeColor(project.snoozeType)
    })
  }

  function onOpenProjectDescription(event, project) {
    if (['svg', 'path', 'BUTTON'].includes(event.target.tagName)) return

    history.push(PROJECT_DETAILS.replace(ROUTE_ENTITY_ID, project.id))
  }

  const columns = [
    {
      Header: 'Project',
      accessor: 'name',
      sortType: 'basic',
      Cell: props => {
        return (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                {props.row.original.snoozed ? (
                  <span
                    className="icon is-small"
                    style={{
                      color: getSnoozeColor(props.row.original.snoozeType),
                    }}
                    data-test={`${props.row.original.name}-color`}
                  >
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ fontSize: '0.5rem' }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div style={{ fontWeight: 600 }}>{props.row.original.name}</div>
            </div>
            {props.row.original.clients?.length ? (
              <div style={{ marginTop: '0.5rem' }}>
                {createClientsNames(props.row.original.clients)}
              </div>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      Header: 'Status',
      Cell: props => {
        const maxCharacters = 120
        if (props.row.original.status) {
          const statusLength = props.row.original.status.length

          let statusPreview = props.row.original.status.substring(
            0,
            maxCharacters,
          )

          if (statusLength > maxCharacters) {
            statusPreview = statusPreview.trim() + '...'
          }

          return (
            <div style={{ color: '#767c8e' }} title={props.row.original.status}>
              {statusPreview}
            </div>
          )
        } else {
          return ''
        }
      },
    },
    {
      Header: 'Started',
      accessor: 'startDate',
      sortType: 'basic',
      Cell: props => formatDate(props.row.original.startDate),
    },
    {
      Header: 'Determination',
      accessor: 'endDate',
      sortType: 'basic',
      width: 150,
      Cell: props => formatDate(props.row.original.endDate),
    },
    {
      Header: 'Assigned To',
      accessor: 'assegnees',
      sortType: 'basic',
      width: 150,
      Cell: props => {
        const maxCharacters = 120
        if (props.row.original.assegnees) {
          const assegneesLength = props.row.original.assegnees.length

          const assegneesPreview = props.row.original.assegnees.substring(
            0,
            maxCharacters,
          )

          return (
            <div data-test="project-assignees">
              {assegneesLength < maxCharacters
                ? assegneesPreview
                : assegneesPreview + ' . . .'}
            </div>
          )
        } else return <div data-test="project-assignees" />
      },
    },
    {
      Header: 'Progress',
      accessor: 'progress',
      sortType: 'basic',
      width: 90,
      Cell: props => {
        return (
          <progress
            className="progress is-info is-small"
            value={props.row.original.progress}
            max="100"
          />
        )
      },
    },
    {
      Header: ' ',
      width: 50,
      Cell: props => {
        return (
          <div className="columns is-vcentered">
            <div className="column is-2">
              <button
                className="button is-small is-light"
                onClick={() => setProjectToSnooze(props.row.original)}
                data-test={`${props.row.original.name}-snooze-button`}
              >
                <span className="icon is-medium">
                  <FontAwesomeIcon icon={faBell} size="lg" />
                </span>
              </button>
            </div>
          </div>
        )
      },
    },
  ]

  const data = projects.map(project => {
    // In case a user is selected both as support and lead we'll show their name only once
    const allAssignees = project?.assignees?.map(({ teamMember }) => teamMember)

    const uniqueProjectAssegnees =
      allAssignees?.reduce((prevAssegnees, currAssegnee) => {
        const isAssegneeDuplicate = !!prevAssegnees?.find(
          assegnee => assegnee.id === currAssegnee.id,
        )

        return isAssegneeDuplicate
          ? prevAssegnees
          : [...prevAssegnees, currAssegnee]
      }, []) ?? []

    const projectAssegnees =
      uniqueProjectAssegnees
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        ?.join(', ') ?? [].join(', ')

    return { ...project, assegnees: projectAssegnees }
  })

  const ColorValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <FontAwesomeIcon
              icon={faFilter}
              style={{ position: 'absolute', left: 8 }}
            />
          )}
          {children}
        </components.ValueContainer>
      )
    )
  }

  const AssegneeValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <FontAwesomeIcon
              icon={faUser}
              style={{ position: 'absolute', left: 8 }}
            />
          )}
          {children}
        </components.ValueContainer>
      )
    )
  }

  const selectStyles = {
    valueContainer: base => ({
      ...base,
      paddingLeft: 24,
    }),
  }

  return (
    <>
      <div className="columns is-vcentered">
        <div className="column ">
          <SearchBar globalFilter={searchBy} setGlobalFilter={onSearchBy} />
        </div>
        <div className="column is-one-fifth">
          <div className="buttons no-flex-wrap">
            <button
              className={cx(
                'button is-fullwidth',
                showingFavoriteProjects
                  ? styles['favorite-project-switch__selected']
                  : styles['favorite-project-switch'],
              )}
              style={{ marginRight: '2px' }}
              onClick={() => seeFavoriteProjects(true)}
            >
              <span>Favorite</span>
            </button>
            <button
              className={cx(
                'button is-fullwidth',
                showingFavoriteProjects
                  ? styles['favorite-project-switch']
                  : styles['favorite-project-switch__selected'],
              )}
              onClick={() => seeFavoriteProjects(false)}
            >
              All
            </button>
          </div>
        </div>
        <div className="column ">
          <Select
            closeMenuOnSelect
            components={{ ValueContainer: ColorValueContainer }}
            className="react-select-container-progress"
            classNamePrefix="react-select-progress"
            styles={selectStyles}
            options={progressFilterOptions}
            name="progressFilter"
            onChange={onSelectProgress}
            defaultValue={{ label: 'Live', value: 'live' }}
            isMulti={true}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(101, 138, 255, 0.35)',
                primary: '#658aff',
              },
            })}
            placeholder="Filter by"
          />
        </div>
        <div className="column ">
          <Select
            closeMenuOnSelect
            components={{ ValueContainer: AssegneeValueContainer }}
            className="react-select-container-progress"
            classNamePrefix="react-select-progress"
            styles={selectStyles}
            options={assegneeFilterOptions}
            name="assegneeFilter"
            onChange={onSelectAssegnee}
            defaultValue={null}
            isMulti={true}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(101, 138, 255, 0.35)',
                primary: '#658aff',
              },
            })}
            placeholder="Filter by"
          />
        </div>
      </div>

      <div id={styles['projects-table']}>
        <ProjectsTable
          data={data}
          columns={columns}
          onOpenProjectDescription={onOpenProjectDescription}
        />

        <SnoozeForm
          open={!!projectToSnooze}
          onClose={() => setProjectToSnooze('')}
          onSnooze={selectedPeriod =>
            onSnoozeProject(projectToSnooze, selectedPeriod)
          }
        />
      </div>
    </>
  )
}

ProjectsTableContainer.propTypes = {
  projects: PropTypes.array,
}

ProjectsTableContainer.defaultProps = {
  projects: [],
}

export default ProjectsTableContainer
