import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { ProvideAuth } from './hooks/use-auth'

import localResolvers from './graphql/resolvers'

import 'react-datepicker/dist/react-datepicker.css'

// Start Apollo GraphQL configuration
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { ApolloProvider } from '@apollo/react-hooks'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const cache = new InMemoryCache()

// // Network config
const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_GRAPHQL_HOST_WS}/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    connectionCallback: error => {
      if (error?.message === 'Unauthorized') {
        console.log(error)
      } else {
        console.log('WS Listening')
      }
    },
  },
})

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_HOST}/graphql`,
  credentials: 'include',
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err?.extensions?.code === 'UNAUTHENTICATED') {
        window.localStorage.removeItem('session')
        window.location.href = '/login'
      } else {
        console.log(err)
      }
    }
  }

  if (networkError && networkError.statusCode === 401) {
    window.localStorage.removeItem('session')
    window.location.href = '/login'
  }
})

const client = new ApolloClient({
  cache,
  link: errorLink.concat(splitLink),
  resolvers: localResolvers,
})

// End Apollo GraphQL configuration

const root = createRoot(document.getElementById('root'))

root.render(
  <ApolloProvider client={client}>
    <ProvideAuth>
      <App client={client} />
    </ProvideAuth>
  </ApolloProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
