import { useState } from 'react';
import { useHistory } from 'react-router-dom'

import { createCardDetailsUrl } from 'constants/routes'
import Table from 'components/table'
import UnstyledButton from 'components/unstyled-button'
import { format } from 'date-fns'

import styles from './cards-table-view.module.scss'
import useCompanySettings from '../../../../../hooks/useCompanySettings'

export default function CardsTableView({ cards }) {
  const [currentPageNumber, setCurrentPageNumber] = useState(0)

  const history = useHistory()

  function renderCardTitle(card) {
    return (
      <UnstyledButton
        onClick={() => history.push(createCardDetailsUrl(card.id))}
      >
        {card.title}
      </UnstyledButton>
    )
  }

  const { preferences } = useCompanySettings()
  const { dateFormat } = preferences ?? {}

  const columns = [
    {
      Header: 'Title',
      accessor: row => renderCardTitle(row),
    },
    {
      Header: 'Project',
      accessor: row => row.project?.name,
    },
    {
      Header: 'Status',
      accessor: row => row.status,
    },
    {
      Header: 'Due by',
      accessor: row =>
        row.dueDate && format(new Date(row.dueDate), dateFormat ?? 'dd/M/yyyy'),
    },
    {
      Header: 'Created By',
      accessor: row => `${row.author.firstName} ${row.author.lastName}`,
    },
    {
      Header: 'Assigned To',
      accessor: row =>
        row.assignee
          ? `${row.assignee?.firstName} ${row.assignee?.lastName}`
          : '',
    },
    {
      Header: 'Priority',
      accessor: row => row.priority,
    },
    {
      Header: 'Size',
      accessor: row => row.size,
    },
  ]

  return (
    <div className={styles.container}>
      <Table
        data={cards}
        columns={columns}
        pageSize={7}
        filterOptions={[]}
        className={styles.table}
        noHeader
        currentPageNumber={currentPageNumber}
        onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
      />
    </div>
  )
}
